import { reactive } from "vue";

let messages = reactive([]);

const guidGenerator = () => {
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
};

const timeouts = [];

const showOSNotification = (title, body) => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(title, { body });
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification(title, { body });
      }
    });
  }
};

export default {
  get messages() {
    return messages;
  },

  add({ title, message, type, level, timeout = 5000 }) {
    const id = guidGenerator();

    if (level === "danger") {
      type = "error";
    }

    messages.push({ id, title, message, type });

    // if window is not active show OS notification
    if (document.visibilityState !== "visible") {
      showOSNotification(title, message);
    }
    this.addTimeout(id, timeout);
  },

  addTimeout(id, timeout = 5000) {
    timeouts[id] = setTimeout(() => this.remove({ id }), timeout);
  },

  removeTimeout(id) {
    clearTimeout(timeouts[id]);
  },

  success(title, message, timeout) {
    this.add({ title, message, type: "success", timeout });
  },
  error(title, message, timeout) {
    this.add({ title, message, type: "error", timeout });
  },
  warning(title, message, timeout) {
    this.add({ title, message, type: "warning", timeout });
  },
  remove(message) {
    const index = messages.findIndex(({ id }) => id === message.id);
    if (index === -1) {
      return;
    }
    messages.splice(index, 1);
  }
};
